import React, { useEffect, useState, useRef } from 'react';
import { FusionLogo, NeumetricLogo, Input, LinkButton, ConfirmationBox, getModuleLink, getModuleURL, OvalLoading, ShieldOval, Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from '@mui/material';
import { userActions, getLogInStatus, isLoading, getCurrentUser, AUDIT_NM_API, KEY_NM_API, getOTPStatus } from "../../store";
import "./style.scss";

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _timer = useRef(null)
  const loading = useSelector(isLoading)
  const logInStatus = useSelector(getLogInStatus)
  const cuser = useSelector(getCurrentUser);
  const isOTPSent = useSelector(getOTPStatus);
  const [state, setState] = useState({ email: '', password: '', otp: '', error: {}, phase: 1, show: false, loginError: '', enableResend: false, showOTPInput: false, enableLogin: false });

  useEffect(() => {
    document.title = 'Console | Sandbox';
    let urlObj = new URLSearchParams(window.location.search);
    let sessionToken = urlObj.get('sessionToken');
    if (sessionToken) {
      localStorage.removeItem(AUDIT_NM_API)
      localStorage.removeItem(KEY_NM_API);
      urlObj.delete('sessionToken')
    }
    else {
      sessionToken = localStorage.getItem(AUDIT_NM_API)
    }
    if (sessionToken) {
      dispatch(userActions.validateToken({ token: sessionToken }));
    } else {
      setState(_ => ({ ..._, show: true }))
    }
  }, []);
  useEffect(() => {
    if (isOTPSent) {
      handleOTPBlink()
      dispatch(userActions.setOTPStatus(false));
    }
  }, [isOTPSent]);
  useEffect(() => {
    if (logInStatus) {
      let source, redirect, next, route, urlObj = new URLSearchParams(window.location.search);
      next = urlObj.get('next');
      redirect = urlObj.get('redirect')
      source = urlObj.get('source')
      if (logInStatus.success) {
        if (next) {
          urlObj.delete('next');
          let otherQueryStr = Array.from(urlObj.keys())
          if (otherQueryStr.length > 0) {
            next += '?';
            otherQueryStr.forEach((key, index) => {
              next += `${key}=${urlObj.get(key)}`;
              if (index !== (otherQueryStr.length - 1)) {
                next += '&'
              }
            })
          }
        }
        if (source) {
          let path = getModuleLink(source, cuser)
          path = redirect ? `${path}&redirect=${redirect}` : path
          window.location.assign(path)
          return
        }
        route = next ? next : ("/" + (redirect || 'O')); //default landing page set to organisation after logging in
        dispatch(userActions.logInSuccessClear());
        if (route) {
          navigate(route, { replace: true });
        }
      } else {
        if (logInStatus.invalidCredentials) {
          setState((_) => (
            { ..._, error: { email: "error", password: 'Invalid credentials! Try again?' } }
          ))
        } else if (logInStatus.invalidOTP) {
          setState((_) => (
            { ..._, error: { otp: "error" } }
          ))
        } else if (logInStatus.enableMultiFactor) {
          setState((_) => ({ ..._, error: {}, enableNext: false, enableOTP: true, enableLogin: false }))
        } else if (!state.show) {
          route = "/login"
          route += (source || redirect) ? "?" : ''
          route += Boolean(source) ? `source=${source}&` : ''
          route += Boolean(redirect) ? `redirect=${encodeURIComponent(decodeURIComponent(redirect))}&` : ''
          setState(_ => ({ ..._, show: true }))
        }
      }
    }
  }, [logInStatus]);

  const handleOTPBlink = () => {
    const resendBtn = document.querySelector(".btn-resend");
    if (!resendBtn) {
      return;
    }
    resendBtn.disabled = true;
    resendBtn.classList.add('blink_me');
    resendBtn.classList.add('c00CC55');
    resendBtn.querySelector('span').innerText = `OTP Sent`;
    resendBtn.classList.remove('hide');
    _timer.current = setTimeout(() => {
      clearInterval(_timer.current)
      _timer.current = null;
      handleResendTimer()
    }, 3000)
  }
  const handleResendTimer = (e) => {
    let count = 30;
    _timer.current = setInterval(() => {
      const resendBtn = document.querySelector(".btn-resend");
      if (count === 30) {
        resendBtn.classList.remove('blink_me');
        resendBtn.classList.remove('c00CC55');
        resendBtn.classList.add('disabled');
      }
      if (count === 0) {
        clearInterval(_timer.current)
        resendBtn.disabled = false;
        resendBtn.querySelector('span').innerText = `Resend OTP`;
        resendBtn.classList.remove('disabled');
        return
      }
      resendBtn.querySelector('span').innerText = `Resend OTP in ${count}s`;
      count--;
    }, 1000)
  }
  const handleResend = () => {
    dispatch(userActions.sendAuthOTP({ email: state.email }));
  }
  const onValueChange = (e) => {
    const { name, value } = e.target;
    let enableNext = false, enableLogin = false;
    if (state.enableOTP && name !== 'otp') {
      return;
    }
    state[name] = value;
    if (name === 'otp') {
      enableLogin = value.length === 4
    } else {
      enableNext = Boolean(state.email && state.password)
    }
    setState((_) => ({ ..._, [name]: value, error: { ..._.error, [name]: false }, enableNext, enableLogin }));
  }
  //ADDING THE NEXT BUTTON FUNCTIONALITY
  const onNextButtonClick = (e) => {
    e.preventDefault();
    const credentials = { email: state.email, password: state.password };
    dispatch(userActions.login(credentials))
  }
  //ADDING THE EMAIL OTP BUTTON FUNCTIONALITY
  const handleEmailOTPClick = (e) => {
    e.preventDefault();
    handleResend(e, false);
    setState((_) => ({ ..._, showOTPInput: true }))
  }
  const onLogInSubmit = (e) => {
    e.preventDefault();
    const credentials = { email: state.email, password: state.password, otp: state.otp };
    dispatch(userActions.login(credentials))
  }
  const handleForgetPassword = () => {
    dispatch(userActions.sendPasswordLinkSuccess(false))
    navigate("/forgot-password")
  }
  const handleWarningOK = (e) => {
    e.preventDefault();
    dispatch(userActions.logInSuccessClear());
  }
  return (
    <div className='login-main row w-100 h-100'>
      {
        state.show ?
          <>
            <div className='col left h-100 v-ctr'>
              <div className='col login-box v-ctr'>
                <a className='logo-link' target='_blank' href="https://www.neumetric.com/fusion">
                  <FusionLogo className='aud-logo ' alt='Neumetric Fusion Logo on Login Page for Security Privacy Compliance and Governance Management System' />
                </a>
                {/* <div className='h3 c-primary exo2'>Login into your Account</div> */}
                <form className='login-form' autoComplete='off' onSubmit={onLogInSubmit}>
                  {/* <p className='h3 exo2 c-primary'>Login</p> */}
                  <Input
                    type="email"
                    className='h11 login-form-input'
                    name='email'
                    placeholder="Email"
                    value={state.email}
                    disabled={state.enableOTP}
                    error={state.error.email}
                    onChange={onValueChange} />

                  <div className='row v-start mt-3 mb-3'>
                    <div className='flex-grow-1'>
                      <Input
                        showError
                        type="password"
                        className='h11 login-form-input login-pwd-input '
                        inputClassName=''
                        name='password'
                        placeholder="Password"
                        value={state.password}
                        disabled={state.enableOTP}
                        error={state.error.password}
                        onChange={onValueChange} />
                    </div>
                    <div className=''>
                      <Button className='bold'
                        color='#15C'
                        text="Next"
                        font='f18'
                        disabled={!state.enableNext}
                        onClick={onNextButtonClick}
                        disableElevation />
                    </div>
                  </div>

                  <div className='row h-btn'>
                    <div className='col w-100 p'>
                      <div className='d-flex h-btn'>
                        <div className='col '>
                          {state.showOTPInput ?
                            <div className='col v-ctr'>
                              <Input
                                type="number"
                                className='h8 otp'
                                name='otp'
                                placeholder="OTP"
                                value={state.otp}
                                error={state.error.otp}
                                onChange={onValueChange} />
                              <LinkButton type="button" className='btn-resend hide' label="Resend OTP" onClick={handleResend} />
                            </div>
                            :
                            <Button className='bold'
                              text="Email OTP"
                              font='f18'
                              color='#15C'
                              disabled={!state.enableOTP}
                              onClick={handleEmailOTPClick}
                              disableElevation />
                          }
                        </div>
                        <div className='col'>
                          {
                            state.error.otp ?
                              <div className='otp-error app-btn cBB2222 f16 v-ctr col h-ctr'>
                                Incorrect OTP
                              </div>
                              :
                              <React.Fragment>
                                <Button
                                  className='bold btn-login'
                                  text="Login"
                                  font='f18'
                                  type="submit"
                                  color='#15C'
                                  disabled={!state.enableLogin}
                                  disableElevation />
                                <LinkButton type="button" className='btn-forget mt-1 f12' label="Forgot Password?" onClick={handleForgetPassword} />
                              </React.Fragment>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className='w-100 tc-row col h-ctr'>
                <div className='w-100 row h-ctr v-ctr'>
                  <div className='w-100 row v-ctr copy-right'>
                    {/* <CopyRight alt="copyright" className="symb" />
                    <a className='h7 copy-right-text' href='https://www.neumetric.com' target="_blank">Neumetric</a> */}
                  </div>
                  {/* <a className='garamond h6'>Terms & Conditions</a>
            <Divider orientation="vertical" classes={{ root: 'divider' }} />
            <a className='garamond h6'>Privacy Policy</a> */}
                </div>
              </div>
            </div>
            <div className='bg-primary right h-100'>
              <a className='logo-link' target='_blank' href="https://www.neumetric.com/">
                <NeumetricLogo className='neu' alt='Neumetric Logo on Login Page for Security Privacy Compliance and Governance Management System' />
              </a>
            </div>
            <div className='row v-end board-container'>
              <ShieldOval className="oval" alt="Neumetric Eval Mascot on Login Page for Security Privacy Compliance and Governance Management System" />
              <div className='board'>
                <div className='f28 exo2 bold title'>Welcome to <span className='exo2 c-primary'>Fusion</span></div>
                <br />
                <div className='login f18 reg'>
                  <span>Comprehensive & centralised management system... </span>
                  <ul className='fea'>
                    <li>Security</li>
                    {/* <li>Risk</li> */}
                    {/* <li>Audit</li> */}
                    {/* <li>Incident</li> */}
                    {/* <li>Vulnerability</li> */}
                    {/* <li>Continuity</li> */}
                    <li>Privacy</li>
                    <li>Compliance</li>
                    <li>Governance</li>
                  </ul>
                </div>
              </div>
            </div>
          </>
          :
          <OvalLoading />
      }
      {
        Boolean(loading) &&
        <div className='overlay-load col v-ctr h-ctr'>
          <CircularProgress sx={{ color: '#FFF' }} />
        </div>
      }
      {
        Boolean(logInStatus && !logInStatus.success && logInStatus.userDisabled) &&
        <ConfirmationBox
          className='login-disable-dialog'
          title='Uh-oh! User inactive!'
          subtitleClassName='f16'
          titleClassName='f22'
          btnOK={{
            color: "#0133CC",
            className: "f14",
            onClick: handleWarningOK
          }}
          subtitle={[
            { html: "Your User Account is marked as Inactive.<br />This prevents you from using Fusion.<br />Reach out to the Organiser of your Enterprise Account", },
            { html: `Alternatively, you can contact <a href=${getModuleURL('support')} target="_blank" class='c0133CC'>Fusion Support</a>.` }
          ]}
        />
      }
      {
        Boolean(logInStatus && !logInStatus.success && logInStatus.orgDisabled) &&
        <ConfirmationBox
          className='login-disable-dialog'
          title='Oops! Organisation inactive!'
          subtitleClassName='f16'
          titleClassName='f22'
          btnOK={{
            color: "#0133CC",
            className: "f14",
            onClick: handleWarningOK
          }}
          subtitle={[
            { html: "Your organisation is inactive in Fusion.<br />Reach out to the Organiser of this Enterprise Account to resolve this issue." },
            { html: `Alternatively, you can contact <a href=${getModuleURL('support')} target="_blank" class='c0133CC'>Fusion Support</a>.` }
          ]}
        />
      }
    </div>
  )
}
